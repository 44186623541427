const YAAD_CURRENCIES = Object.freeze({
    ILS: 1,
    USD: 2,
    EUR: 3,
    GBP: 4,
});

const NEDARIM_CURRENCIES = Object.freeze({
    ILS: 1,
    USD: 2,
});

const PAYMENT_METHODS = Object.freeze({
    AAC: 'aac',
    APPLE_PAY: 'applepay',
    ASSERBISHVIL: 'asserbishvil',
    BIT: 'bit',
    BROOM: 'broom',
    CARD: 'card',
    DONORSFUND: 'donorsfund',
    GOOGLE_PAY: 'googlepay',
    OJCFUND: 'ojcfund',
    PAYPAL: 'paypal',
    CASHAPP: 'cashapp',
    IDEAL: 'ideal',
    ACH: 'ach',
    CHARIOT: 'chariot',
    PLEDGER: 'pledger',
    MATBIA: 'matbia',
});

const PAYMENT_METHODS_INFO = Object.freeze({
    [PAYMENT_METHODS.CARD]: {
        label: 'Credit Card',
        type: 'gateway',
        tipping: true,
        configProp: 'enableCardPayments',
        labelProp: 'paymentMethods.creditCard',
        giftAid: true,
    },
    [PAYMENT_METHODS.BIT]: {
        label: 'Bit Payments',
        type: 'gateway',
        tipping: true,
        configProp: 'enableBitPayments',
        labelProp: 'paymentMethods.bitPayments',
    },
    [PAYMENT_METHODS.PAYPAL]: {
        label: 'Paypal',
        type: 'gateway',
        tipping: true,
        configProp: 'enablePaypalPayments',
        labelProp: 'paymentMethods.paypal',
    },
    [PAYMENT_METHODS.APPLE_PAY]: {
        label: 'ApplePay',
        type: 'gateway',
        tipping: true,
        configProp: 'enableApplePay',
        labelProp: 'paymentMethods.applePay',
        giftAid: true,
    },
    [PAYMENT_METHODS.GOOGLE_PAY]: {
        label: 'GooglePay',
        type: 'gateway',
        tipping: true,
        configProp: 'enableGooglePay',
        labelProp: 'paymentMethods.googlePay',
        giftAid: true,
    },
    [PAYMENT_METHODS.CASHAPP]: {
        label: 'Cash App',
        type: 'gateway',
        tipping: true,
        configProp: 'enableCashApp',
        labelProp: 'paymentMethods.cashApp',
    },
    [PAYMENT_METHODS.IDEAL]: {
        label: 'iDEAL',
        type: 'gateway',
        tipping: true,
        configProp: 'enableIdeal',
        labelProp: 'paymentMethods.ideal',
    },
    [PAYMENT_METHODS.ACH]: {
        label: 'ACH',
        type: 'gateway',
        tipping: true,
        configProp: 'enableAch',
        labelProp: 'paymentMethods.ach',
    },
    [PAYMENT_METHODS.CHARIOT]: {
        label: 'Chariot',
        type: 'gateway',
        tipping: false,
        configProp: 'enableChariot',
        labelProp: 'paymentMethods.chariot',
    },
    [PAYMENT_METHODS.PLEDGER]: {
        label: 'Pledger',
        type: 'gateway',
        tipping: false,
        configProp: 'enablePledger',
        labelProp: 'paymentMethods.pledger',
    },
    [PAYMENT_METHODS.MATBIA]: {
        label: 'Matbia',
        type: 'gateway',
        tipping: false,
        configProp: 'enableMatbia',
        labelProp: 'paymentMethods.matbia',
    },
    [PAYMENT_METHODS.AAC]: {
        label: 'Achisomoch',
        type: 'voucher',
        tipping: false,
        configProp: 'enableAac',
        labelProp: 'paymentMethods.achisomoch',
    },
    [PAYMENT_METHODS.DONORSFUND]: {
        label: 'Donors Fund',
        type: 'voucher',
        tipping: false,
        configProp: 'enableDonorsFund',
        labelProp: 'paymentMethods.donorsFund',
    },
    [PAYMENT_METHODS.OJCFUND]: {
        label: 'OJC Fund',
        type: 'voucher',
        tipping: false,
        configProp: 'enableOjcFund',
        labelProp: 'paymentMethods.ojcFund',
    },
    [PAYMENT_METHODS.ASSERBISHVIL]: {
        label: 'Asser Bishvil',
        type: 'voucher',
        tipping: false,
        configProp: 'enableAsserBishvil',
        labelProp: 'paymentMethods.asserBishvil',
    },
    [PAYMENT_METHODS.BROOM]: {
        label: 'Broom',
        type: 'voucher',
        tipping: false,
        configProp: 'enableBroom',
        labelProp: 'paymentMethods.broom',
    },
});

const PAYMENT_METHODS_VALUES = Object.freeze([
    ...Object.keys(PAYMENT_METHODS_INFO),
    'offline',
    'api',
]);

const PAYMENT_GATEWAYS = Object.freeze({
    STRIPE: 'stripe',
    YAADPAY: 'yaadpay',
    OFFLINE: 'offline',
    PAYME: 'payme',
    CARDKNOX: 'cardknox',
    NEDARIM: 'nedarim',
    NMI: 'nmi',
    PAYPAL: 'paypal',
    BANQUEST: 'banquest',
    PAYFAST: 'payfast',
    MESHULAM: 'meshulam',
    TRANZILA: 'tranzila',
    BULK_IMPORT: 'Bulk Import',
    API: 'api',
    AAC: 'aac',
    DONORSFUND: 'donorsfund',
    OJCFUND: 'ojcfund',
    CHARIOT: 'chariot',
    ASSERBISHVIL: 'asserbishvil',
    BROOM: 'broom',
    AUTHORIZE: 'authorize',
});

const PAYMENT_GATEWAY_VALUES = Object.freeze(Object.values(PAYMENT_GATEWAYS));

const OFFLINE_GATEWAYS = Object.freeze({
    OFFLINE: 'offline',
    BULK_IMPORT: 'Bulk Import',
});

const OFFLINE_GATEWAY_VALUES = Object.freeze(Object.values(OFFLINE_GATEWAYS));

const LIVE_GATEWAYS = Object.freeze({
    STRIPE: 'stripe',
    YAADPAY: 'yaadpay',
    PAYME: 'payme',
    CARDKNOX: 'cardknox',
    NEDARIM: 'nedarim',
    NMI: 'nmi',
    PAYPAL: 'paypal',
    BANQUEST: 'banquest',
    AAC: 'aac',
    DONORSFUND: 'donorsfund',
    OJCFUND: 'ojcfund',
    PAYFAST: 'payfast',
    MESHULAM: 'meshulam',
    TRANZILA: 'tranzila',
    CHARIOT: 'chariot',
    ASSERBISHVIL: 'asserbishvil',
    BROOM: 'broom',
    AUTHORIZE: 'authorize',
});

const LIVE_GATEWAY_VALUES = Object.freeze(Object.values(LIVE_GATEWAYS));

const PAYMENT_MODES = Object.freeze({
    REGULAR: 'regular',
    MONTHLY: 'monthly',
    INSTALLMENTS: 'installments',
    UNLIMITED_RECURRING: 'unlimitedRecurring',
    RECURRING: 'recurring',
});

const PAYMENT_MODE_VALUES = Object.freeze(Object.values(PAYMENT_MODES));

const UNLIMITED_RECURRING_EFFECTIVE_MONTHS = 12;
const MIN_PAYMENT_PERIOD = 2;
const MAX_PAYMENT_PERIOD = 120;

const PAYMENT_TRANSACTION_STATUSES = Object.freeze({
    CREATED: 'created',
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILURE: 'failure',
});

const PAYMENT_TRANSACTION_STATUS_VALUES = Object.freeze(
    Object.values(PAYMENT_TRANSACTION_STATUSES),
);

const PAYMENT_TRANSACTION_EVENTS = Object.freeze({
    PAYMENT_SUCCEEDED: 'payment_succeeded',
    PAYMENT_FAILED: 'payment_failed',
    REFUND: 'refund',
    DISPUTE: 'dispute',
    DISPUTE_CLOSED: 'dispute_closed',
});

const PAYMENT_TRANSACTION_EVENT_VALUES = Object.freeze(
    Object.values(PAYMENT_TRANSACTION_EVENTS),
);

const STRIPE_METADATA_KEY = 'cm_donation_order_id';
const STRIPE_PEF_KEY = 'PEF Charity ID';

const CC_TYPES = Object.freeze({
    VISA: 'Visa',
    MASTERCARD: 'MasterCard',
    DISCOVER: 'Discover',
    AMERICAN_EXPRESS: 'American Express',
});

const STRIPE_CONNECT_STATUS = Object.freeze({
    UNCONNECTED: 'unConnected',
    CONNECTED: 'connected',
    PENDING: 'pending',
    DISCONNECTED: 'disconnected',
});

const STRIPE_CONNECT_TYPES = Object.freeze({
    CAMPAIGN: 'campaign',
    DONATE_PAGE: 'donatePage',
});

const STRIPE_EVENTS = Object.freeze({
    PAYMENT_INTENT_SUCCEEDED: 'payment_intent.succeeded',
    PAYMENT_INTENT_PAYMENT_FAILED: 'payment_intent.payment_failed',
    INVOICE_PAYMENT_SUCCEEDED: 'invoice.payment_succeeded',
    INVOICE_PAYMENT_FAILED: 'invoice.payment_failed',
    CHARGE_REFUND_UPDATED: 'charge.refund.updated',
    CHARGE_DISPUTE_FUNDS_WITHDRAWN: 'charge.dispute.funds_withdrawn',
    CHARGE_DISPUTE_CLOSED: 'charge.dispute.closed',
});

module.exports = {
    YAAD_CURRENCIES,
    NEDARIM_CURRENCIES,
    PAYMENT_METHODS,
    PAYMENT_METHODS_INFO,
    PAYMENT_METHODS_VALUES,
    PAYMENT_GATEWAYS,
    PAYMENT_GATEWAY_VALUES,
    PAYMENT_MODES,
    PAYMENT_MODE_VALUES,
    PAYMENT_TRANSACTION_STATUSES,
    PAYMENT_TRANSACTION_STATUS_VALUES,
    PAYMENT_TRANSACTION_EVENTS,
    PAYMENT_TRANSACTION_EVENT_VALUES,
    STRIPE_CONNECT_TYPES,
    STRIPE_CONNECT_STATUS,
    STRIPE_METADATA_KEY,
    STRIPE_PEF_KEY,
    STRIPE_EVENTS,
    OFFLINE_GATEWAYS,
    OFFLINE_GATEWAY_VALUES,
    LIVE_GATEWAYS,
    LIVE_GATEWAY_VALUES,
    CC_TYPES,
    UNLIMITED_RECURRING_EFFECTIVE_MONTHS,
    MIN_PAYMENT_PERIOD,
    MAX_PAYMENT_PERIOD,
};
