const AUTOMATIC_ERROR_REPLY_BODY_VALUE =
    'Message delivery failed. Please contact the organization directly';

const AUTOMATIC_SUCCESS_REPLY_BODY_VALUE =
    'This is an automatic message, to let you know that your message has been forwarded to the organization';

const DONATION_MESSAGE_SOURCE = Object.freeze({
    CAMPAIGN_COMPARE_CONNECT: 'campaign-compare-connect',
    DONOR_CONNECT: 'donor-connect',
    DONOR_RESCUE: 'donor-rescue',
});

const INCOMING_MESSAGE_REPLY_BODY_VALUE =
    "There's a reply from {{NAME}} {{PHONE_NUMBER}} - {{BODY}}";

const MAX_MESSAGE_BATCH_SIZE = 1_000;

const MESSAGE_ATTACHMENT_TYPES = Object.freeze({
    CANADA_TAX_RECEIPT_PDF: 'canada_tax_receipt_pdf',
});

const MESSAGE_ATTACHMENT_TYPE_VALUES = Object.freeze(
    Object.values(MESSAGE_ATTACHMENT_TYPES),
);

const MESSAGE_CONTENT_TYPES = Object.freeze({
    HTML: 'html',
    PDF: 'pdf',
    TEXT: 'text',
});

const MESSAGE_CONTENT_TYPE_VALUES = Object.freeze(
    Object.values(MESSAGE_CONTENT_TYPES),
);

const MESSAGE_EVENT_STATUS = Object.freeze({
    OKAY: 'okay',
    NOT_FOUND: 'not found',
    UNSUPPORTED: 'unsupported',
});

const MESSAGE_PROVIDERS = Object.freeze({
    MAILGUN: 'mailgun',
    TWILIO: 'twilio',
});

const MESSAGE_PROVIDER_ID = Object.freeze({
    TWILIO: process.env.TWILIO_MESSAGING_SERVICE_SID,
});

const MESSAGE_RECIPIENTS_DUPLICATE_POLICY = Object.freeze({
    ALL: 'all',
    UNIQUE: 'unique',
});

const MESSAGE_SEND_ERRORS = Object.freeze({
    BALANCE_EXHAUSTED: 'Messaging Balance Exhausted',
    CONNECT_DISABLED: 'Connect Disabled',
    DUPLICATE_RECIPIENTS: 'Duplicate Recipients Found',
    INSUFFICENT_BALANCE: 'Insufficient Messaging Balance',
    INVALID_RECIPIENTS: 'No valid recipients were selected',
    UNSUBSCRIBED_RECIPIENTS: 'Unsubscribed Recipients Found',
});

const MESSAGE_TRANSPORT_TYPES = Object.freeze({
    EMAIL: 'email',
    SMS: 'sms',
    WHATSAPP: 'whatsapp',
});

const MESSAGE_TRANSPORT_TYPE_VALUES = Object.freeze(
    Object.values(MESSAGE_TRANSPORT_TYPES),
);

const MESSAGE_UNSUBSCRIBE_SCOPE = Object.freeze({
    AMBASSADOR_CONNECT: 'ambassador-connect',
    [DONATION_MESSAGE_SOURCE.CAMPAIGN_COMPARE_CONNECT]:
        DONATION_MESSAGE_SOURCE.CAMPAIGN_COMPARE_CONNECT,
    [DONATION_MESSAGE_SOURCE.DONOR_CONNECT]:
        DONATION_MESSAGE_SOURCE.DONOR_CONNECT,
});

const MESSAGE_UTM_SOURCE = Object.freeze({
    [MESSAGE_UNSUBSCRIBE_SCOPE.AMBASSADOR_CONNECT]: 'cm_ac',
    [DONATION_MESSAGE_SOURCE.CAMPAIGN_COMPARE_CONNECT]: 'cm_cc',
    [DONATION_MESSAGE_SOURCE.DONOR_CONNECT]: 'cm_dc',
    [DONATION_MESSAGE_SOURCE.DONOR_RESCUE]: 'cm_dr',
});

const DONOR_RESCUE_MAIL_UTM_QUERYSTRING = `?utm_source=${
    MESSAGE_UTM_SOURCE[DONATION_MESSAGE_SOURCE.DONOR_RESCUE]
}&utm_medium=${MESSAGE_TRANSPORT_TYPES.EMAIL}`;

const MESSAGING_OPTION = Object.freeze({
    OFF: 'off',
    METERED: 'metered',
    UNMETERED: 'unmetered',
});

const RecipientMessageStatuses = Object.freeze({
    PENDING: 'pending',
    QUEUED: 'queued',
    SENT: 'sent',
    OPENED: 'opened',
    CLICKED: 'clicked',
    FAILED: 'failed',
    SUBSCRIBED: 'subscribed',
    UNSUBSCRIBED: 'unsubscribed',
});

const SELECTION_MODE = Object.freeze({
    INCLUDE: 'include',
    EXCLUDE: 'exclude',
});

module.exports = {
    AUTOMATIC_ERROR_REPLY_BODY_VALUE,
    AUTOMATIC_SUCCESS_REPLY_BODY_VALUE,
    DONATION_MESSAGE_SOURCE,
    INCOMING_MESSAGE_REPLY_BODY_VALUE,
    MAX_MESSAGE_BATCH_SIZE,
    MESSAGE_ATTACHMENT_TYPES,
    MESSAGE_ATTACHMENT_TYPE_VALUES,
    MESSAGE_CONTENT_TYPES,
    MESSAGE_CONTENT_TYPE_VALUES,
    MESSAGE_EVENT_STATUS,
    MESSAGE_PROVIDERS,
    MESSAGE_PROVIDER_ID,
    MESSAGE_RECIPIENTS_DUPLICATE_POLICY,
    MESSAGE_SEND_ERRORS,
    MESSAGE_TRANSPORT_TYPES,
    MESSAGE_TRANSPORT_TYPE_VALUES,
    MESSAGE_UNSUBSCRIBE_SCOPE,
    MESSAGING_OPTION,
    RecipientMessageStatuses,
    MESSAGE_UTM_SOURCE,
    DONOR_RESCUE_MAIL_UTM_QUERYSTRING,
    SELECTION_MODE,
};
