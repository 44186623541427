const GATEWAYS = Object.freeze({
    stripe: {
        label: 'Stripe',
        flow: 'intent',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: true,
        tipping: true,
        receiptName: true,
        support: {
            card: { all: true },
            googlepay: { all: true },
            applepay: { all: true },
            cashapp: { currencies: ['USD'] },
            ideal: { currencies: ['EUR'] },
        },
    },
    yaadpay: {
        label: 'YaadPay',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        support: {
            card: { currencies: ['GBP', 'USD', 'ILS', 'EUR'] },
        },
    },
    offline: {
        label: 'Offline',
        flow: 'offline',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        defaultPaymentMethod: 'offline',
    },
    api: {
        label: 'API',
        flow: 'offline',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        defaultPaymentMethod: 'offline',
    },
    payme: {
        label: 'Payme',
        flow: { card: 'charge', bit: 'awayPre' },
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        paymentMethods: ['card', 'bit'],
        support: {
            card: { currencies: ['GBP', 'USD', 'ILS', 'EUR'] },
            bit: { currencies: ['ILS'] },
        },
    },
    cardknox: {
        label: 'Cardknox',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        support: {
            card: { all: true },
            googlepay: { all: true },
            pledger: { currencies: ['USD'] },
            matbia: { currencies: ['USD'] },
        },
    },
    nedarim: {
        label: 'Nedarim',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['USD', 'ILS'] },
        },
    },
    nmi: {
        label: 'NMI',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['USD'] },
        },
    },
    paypal: {
        label: 'Paypal',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            paypal: { all: true },
        },
        defaultPaymentMethod: 'paypal',
    },
    banquest: {
        label: 'Banquest',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        support: {
            card: { currencies: ['USD'] },
        },
    },
    payfast: {
        label: 'Payfast',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['ZAR'] },
        },
    },
    meshulam: {
        label: 'Meshulam',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: true,
        support: {
            card: { currencies: ['ILS'] },
            bit: { currencies: ['ILS'] },
            googlepay: { currencies: ['ILS'] },
            applepay: { currencies: ['ILS'] },
        },
    },
    tranzila: {
        label: 'Tranzila',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: true,
        unlimitedRecurring: false,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['ILS'] },
        },
    },

    chariot: {
        label: 'Chariot',
        flow: 'charge',
        regular: true,
        monthly: false,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        receiptName: true,
        support: {
            chariot: { currencies: ['USD'] },
        },
        defaultPaymentMethod: 'chariot',
    },

    donorsfund: {
        label: 'The Donors Fund',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            donorsfund: { currencies: ['USD'] },
        },
        defaultPaymentMethod: 'donorsfund',
        isVoucher: true,
    },

    ojcfund: {
        label: 'OJC Fund',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            ojcfund: { currencies: ['USD'] },
        },
        defaultPaymentMethod: 'ojcfund',
        isVoucher: true,
    },
    aac: {
        label: 'Achisomoch',
        flow: 'awayPre',
        regular: true,
        monthly: false,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            aac: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'aac',
        isVoucher: true,
    },
    asserbishvil: {
        label: 'Asser Bishvil',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            asserbishvil: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'asserbishvil',
        isVoucher: true,
        apiUrl: 'https://online.asserbishvil.org.uk/api',
    },
    broom: {
        label: 'Broom',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            broom: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'broom',
        isVoucher: true,
        apiUrl: 'https://online.broomfoundation.org.uk/api',
    },
    belz: {
        label: 'Belz',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            belz: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'belz',
        isVoucher: true,
        apiUrl: 'https://belzvouchers.com/api',
    },
    cmz: {
        label: 'CMZ',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            cmz: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'cmz',
        isVoucher: true,
        apiUrl: 'https://online.cmz.org.uk/api',
    },
    kolyom: {
        label: 'Kol Yom',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            kolyom: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'kolyom',
        isVoucher: true,
        apiUrl: 'https://online.kolyomtrust.org.uk/api',
    },
    mhtrust: {
        label: 'MH Trust',
        flow: 'awayPre',
        regular: true,
        monthly: false,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            mhtrust: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'mhtrust',
        isVoucher: true,
        apiUrl: 'https://mhtrust.org.uk/api',
    },
    yadshlomo: {
        label: 'Yad Shlomo',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            yadshlomo: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'yadshlomo',
        isVoucher: true,
        apiUrl: 'https://yadshlomo.org/api',
    },
    zoreyatzedokos: {
        label: 'Zoreya Tzedokos',
        flow: 'awayPre',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: false,
        tipping: false,
        support: {
            zoreyatzedokos: { currencies: ['GBP'] },
        },
        defaultPaymentMethod: 'zoreyatzedokos',
        isVoucher: true,
        apiUrl: 'https://online.zoreyatzedokos.org.uk/api',
    },
    authorize: {
        label: 'Authorize',
        flow: 'charge',
        regular: true,
        monthly: true,
        installments: false,
        unlimitedRecurring: true,
        tipping: true,
        receiptName: false,
        support: {
            card: { currencies: ['USD', 'CAD', 'GBP', 'EUR', 'AUD', 'NZD'] },
        },
    },
});

module.exports = {
    GATEWAYS,
};
