import { createSlice, createSelector } from '@reduxjs/toolkit';
import { get, pick } from 'lodash';
import config from 'client/config';
import {
    selectPayments,
    selectCurrencies,
    selectCheckoutCustomFields,
    selectCurrency as selectDefaultCurrency,
    selectPaymentMethod as selectDefaultPaymentMethod,
} from '../campaign/campaignSlice';
import { minDonationAmount } from 'common/helpers/donation';
import {
    getDefaultPaymentMethod,
    getPaymentConfig,
    isPaymentMethodEnabled,
} from 'common/helpers/payments';
import { resetCheckoutPopup } from './checkoutPopupSlice';
import { paymentMethodType } from 'common/helpers/paymentMethods';
import {
    PAYMENT_GATEWAYS,
    PAYMENT_METHODS,
    PAYMENT_MODES,
} from 'common/constants/payments';
import { voucherSupportsPaymentMode } from 'common/helpers/vouchers';
import { getDynamicTipConfig } from 'common/helpers/tipping';
import { autofillParameters } from './autofillParameters';
import { isVoucherGateway } from 'common/helpers/gateways';

export const STATE_PREFIX = 'checkout';
export const PAYMENT_PROCESS_IDLE = `${STATE_PREFIX}/idle`;
export const PAYMENT_PROCESS_PENDING = `${STATE_PREFIX}/pending`;
export const PAYMENT_PROCESS_FAILED = `${STATE_PREFIX}/failed`;
export const PAYMENT_PROCESS_REDIRECT = `${STATE_PREFIX}/redirect`;

const isPaymentMethodAllowed = (
    currencyPayments,
    givingId,
    givingPaymentOptions,
    paymentMethod,
) => {
    const isEnabled = isPaymentMethodEnabled(currencyPayments, paymentMethod);
    if (!isEnabled || !givingId || !givingPaymentOptions) {
        return isEnabled;
    }

    const paymentConfig = getPaymentConfig(currencyPayments, paymentMethod);
    const paymentModeProp =
        givingPaymentOptions.type === 'recurring'
            ? 'allowRecurring'
            : 'allowSinglePayment';

    return Boolean(get(paymentConfig, paymentModeProp, false));
};

const autoFilledValues = autofillParameters();

const initialState = Object.freeze({
    donationAmount: null,
    donationCurrency: null,
    autoFilledValues,
    donationTotal: {
        tipAmount: null,
        totalAmount: null,
        monthlyAmount: null,
    },
    donationPaymentMethod: null,
    paymentProcessCount: null,
    paymentProcessIteration: null,
    paymentProcessStatus: PAYMENT_PROCESS_IDLE,
    error: null,
    alert: null,
    givingCount: null,
    givingId: null,
    matchMyGift: false,
    matchedDonor: null,
    givingAmount: 0,
    givingPaymentOptions: {
        type: 'regular',
        duration: 0,
    },
    months: {
        recurringMonths: 0,
        installmentMonths: 0,
    },
    browserSupport: {
        applePay: false,
        googlePay: false,
    },
    isGatewayRedirect: false,
});

const checkout = createSlice({
    name: STATE_PREFIX,
    initialState,
    reducers: {
        updateDonationAmount(state, { payload }) {
            let amount = Number(`${payload}`.replace(/\./g, ''));

            if (!isNaN(amount)) {
                if (amount < 0) {
                    amount = 0;
                }

                state.donationAmount = amount;
            }
            state.error = null;
            state.paymentProcessStatus = PAYMENT_PROCESS_IDLE;
        },
        updateDonationCurrency(state, { payload }) {
            state.donationCurrency = String(payload);
            state.error = null;
            state.paymentProcessStatus = PAYMENT_PROCESS_IDLE;
        },
        updateDonationTotal(state, { payload }) {
            state.donationTotal.tipAmount = payload.tipAmount;
            state.donationTotal.totalAmount = payload.totalAmount;
            state.donationTotal.monthlyAmount = payload.monthlyAmount;
            state.error = null;
            state.paymentProcessStatus = PAYMENT_PROCESS_IDLE;
        },
        updateDonationPaymentMethod(state, { payload }) {
            state.donationPaymentMethod = String(payload);
            state.error = null;
            state.paymentProcessStatus = PAYMENT_PROCESS_IDLE;
        },
        resetCheckout() {
            return initialState;
        },
        startPaymentProcess(state, { payload }) {
            state.error = null;
            state.paymentProcessCount = payload;
            state.paymentProcessIteration = 1;
            state.paymentProcessStatus = PAYMENT_PROCESS_PENDING;
        },
        paymentIterationComplete(state) {
            state.paymentProcessIteration = state.paymentProcessIteration + 1;
            if (state.paymentProcessIteration > state.paymentProcessCount) {
                state.paymentProcessStatus = PAYMENT_PROCESS_IDLE;
                state.paymentProcessCount = null;
                state.paymentProcessIteration = null;
            }
            state.alert = null;
        },
        paymentFailed(state, { payload }) {
            state.error = payload;
            state.alert = null;
            state.paymentProcessStatus = PAYMENT_PROCESS_IDLE;
        },
        setPaymentAlert(state, { payload }) {
            state.error = null;
            state.alert = payload;
        },
        updateGivingCount(state, { payload }) {
            state.givingCount = payload;
        },
        updateGivingAmount(state, { payload }) {
            state.givingAmount = payload;
        },
        updateGivingId(state, { payload }) {
            state.givingId = payload;
        },
        updateGivingPaymentOptions(state, { payload }) {
            state.givingPaymentOptions.type = payload.type || 'single';
            state.givingPaymentOptions.duration = payload.duration || 0;
        },
        resetGiving(state) {
            state.givingId = null;
            state.givingAmount = 0;
            state.givingCount = null;
            state.givingPaymentOptions = {
                type: 'regular',
                duration: 0,
            };
        },
        updateInstallmentMonths(state, { payload }) {
            state.months.installmentMonths = payload;
        },
        updateRecurringMonths(state, { payload }) {
            state.months.recurringMonths = payload;
        },
        updateMatchMyGift(state, { payload }) {
            state.matchMyGift = payload;
        },
        updateMatchedDonor(state, { payload }) {
            state.matchedDonor = payload;
        },
        updateGooglePaySupport(state, { payload }) {
            state.browserSupport.googlePay = payload;
        },
        updateApplePaySupport(state, { payload }) {
            state.browserSupport.applePay = payload;
        },
        setGatewayRedirect(state) {
            state.isGatewayRedirect = true;
            state.paymentProcessStatus = PAYMENT_PROCESS_REDIRECT;
        },
        resetAutoFilledValues(state) {
            state.autoFilledValues = {};
        },
    },
    extraReducers: builder => {
        builder.addCase(resetCheckoutPopup, () => {
            return initialState;
        });
    },
});

export const {
    updateDonationAmount,
    updateDonationCurrency,
    updateDonationTotal,
    updateDonationPaymentMethod,
    resetCheckout,
    startPaymentProcess,
    paymentIterationComplete,
    paymentFailed,
    setPaymentAlert,
    updateGivingCount,
    updateGivingId,
    updateGivingPaymentOptions,
    updateInstallmentMonths,
    updateRecurringMonths,
    updateGivingAmount,
    updateMatchMyGift,
    updateMatchedDonor,
    updateGooglePaySupport,
    updateApplePaySupport,
    setGatewayRedirect,
    resetGiving,
    resetAutoFilledValues,
    setAbandonedDonationLogged,
} = checkout.actions;

export const selectCheckout = state => state[STATE_PREFIX];

export const selectAutoFilledValues = createSelector(selectCheckout, checkout =>
    get(checkout, 'autoFilledValues'),
);

export const selectAutoFilledAmount = createSelector(
    selectAutoFilledValues,
    values => get(values, 'amount', null),
);

export const selectAutoFilledCurrency = createSelector(
    selectAutoFilledValues,
    selectCurrencies,
    (values, currencies) =>
        currencies
            .map(currency => currency.name)
            .find(currency => currency === get(values, 'currency', null)) ||
        null,
);

export const selectAutoFilledPaymentMode = createSelector(
    selectAutoFilledValues,
    values => get(values, 'paymentMode', null),
);

export const selectAutoFilledPaymentMethod = createSelector(
    selectAutoFilledValues,
    values => get(values, 'paymentMethod', null),
);

export const selectDonationAmount = createSelector(
    selectCheckout,
    selectAutoFilledAmount,
    (checkout, autoFilledAmount) =>
        checkout.donationAmount || autoFilledAmount || 0,
);
export const selectDonationTotal = createSelector(
    selectCheckout,
    checkout => checkout.donationTotal,
);
export const selectGivingId = createSelector(
    selectCheckout,
    checkout => checkout.givingId || 0,
);
export const selectGivingCount = createSelector(
    selectCheckout,
    checkout => checkout.givingCount || null,
);
export const selectGivingAmount = createSelector(
    selectCheckout,
    checkout => checkout.givingAmount || null,
);
export const selectGivingPaymentOptions = createSelector(
    selectCheckout,
    checkout => checkout.givingPaymentOptions,
);
export const selectMatchMyGift = createSelector(
    selectCheckout,
    checkout => checkout.matchMyGift,
);
export const selectMatchedDonor = createSelector(
    selectCheckout,
    checkout => checkout.matchedDonor,
);
export const selectBrowserSupport = createSelector(
    selectCheckout,
    checkout => checkout.browserSupport,
);
export const selectIsGatewayRedirect = createSelector(
    selectCheckout,
    checkout => checkout.isGatewayRedirect,
);
export const selectInstallmentMonths = createSelector(
    selectCheckout,
    checkout => checkout.months.installmentMonths || 0,
);
export const selectRecurringMonths = createSelector(
    selectCheckout,
    checkout => checkout.months.recurringMonths || 0,
);
export const selectDonationCurrency = createSelector(
    selectCheckout,
    selectDefaultCurrency,
    selectAutoFilledCurrency,
    (checkout, defaultCurrency, autoFilledCurrency) =>
        checkout.donationCurrency ||
        autoFilledCurrency ||
        defaultCurrency ||
        'USD',
);

export const selectCurrencyPayments = createSelector(
    selectPayments,
    selectDonationCurrency,
    (campaignPayments, currency) => {
        const currencyPayments = campaignPayments[currency];
        return Array.isArray(currencyPayments) && currencyPayments.length
            ? currencyPayments
            : null;
    },
);

export const selectVouchers = createSelector(
    selectCurrencyPayments,
    currencyPayments =>
        currencyPayments
            ?.filter(config => isVoucherGateway(config.gateway))
            .map(voucher => voucher.gateway),
);

export const selectDonationPaymentMethod = createSelector(
    selectCheckout,
    selectPayments,
    selectVouchers,
    selectDonationCurrency,
    selectDefaultPaymentMethod,
    selectAutoFilledPaymentMethod,
    selectBrowserSupport,
    (
        checkout,
        payments,
        vouchers,
        currency,
        defaultConfiguredPaymentMethod,
        autoFilledPaymentMethod,
        browserSupport,
    ) => {
        let donationPaymentMethod;

        const paymentMethod =
            checkout.donationPaymentMethod || autoFilledPaymentMethod;
        const currencyPayments = get(payments, currency, []);

        const excludedPaymentMethods = [];
        if (!browserSupport.googlePay) {
            excludedPaymentMethods.push(PAYMENT_METHODS.GOOGLE_PAY);
        }
        if (!browserSupport.applePay) {
            excludedPaymentMethods.push(PAYMENT_METHODS.APPLE_PAY);
        }

        if (
            !isPaymentMethodEnabled(currencyPayments, paymentMethod) &&
            !vouchers.includes(paymentMethod)
        ) {
            donationPaymentMethod = getDefaultPaymentMethod(
                payments,
                currency,
                { excludedPaymentMethods },
            );
        }

        if (!donationPaymentMethod) {
            donationPaymentMethod =
                paymentMethod || defaultConfiguredPaymentMethod || 'card';
        }

        if (
            !isPaymentMethodAllowed(
                currencyPayments,
                checkout.givingId,
                checkout.givingPaymentOptions,
                donationPaymentMethod,
            )
        ) {
            let paymentModeProp;
            if (checkout.givingPaymentOptions) {
                paymentModeProp =
                    checkout.givingPaymentOptions.type === 'recurring'
                        ? 'allowRecurring'
                        : 'allowSinglePayment';
            }
            donationPaymentMethod = getDefaultPaymentMethod(
                payments,
                currency,
                {
                    excludedPaymentMethods,
                    paymentModeProp,
                },
            );
        }

        return donationPaymentMethod || vouchers?.[0] || 'card';
    },
);

export const selectPayment = createSelector(
    selectCurrencyPayments,
    selectDonationPaymentMethod,
    selectCheckout,
    (currencyPayments, paymentMethod, checkout) => {
        if (!currencyPayments) {
            return null;
        }

        const payment = getPaymentConfig(currencyPayments, paymentMethod);
        if (!checkout.givingId) {
            return payment || null;
        }
        return {
            ...payment,
            allowSinglePayment: checkout.givingPaymentOptions.type === 'single',
            allowInstallments: false,
            allowUnlimitedRecurring: false,
            allowRecurring: checkout.givingPaymentOptions.type === 'recurring',
        };
    },
);
export const selectPaymentGateway = createSelector(
    selectPayment,
    payment => payment.gateway || 'stripe',
);
export const selectIsTestMode = createSelector(
    selectPayments,
    campaignPayments => campaignPayments.testMode || false,
);
export const selectBitEnabled = createSelector(
    selectCurrencyPayments,
    selectGivingId,
    selectGivingPaymentOptions,
    (currencyPayments, givingId, givingOptions) => {
        if (givingId && givingOptions.type !== 'single') {
            return false;
        }
        return isPaymentMethodEnabled(currencyPayments, 'bit');
    },
);
export const selectPaypalEnabled = createSelector(
    selectCurrencyPayments,
    currencyPayments => {
        return isPaymentMethodEnabled(currencyPayments, 'paypal');
    },
);
export const selectAppleAndGooglePayEnabled = createSelector(
    selectCurrencyPayments,
    selectBrowserSupport,
    (currencyPayments, browserSupport) => ({
        googlePay:
            isPaymentMethodEnabled(currencyPayments, 'googlepay') &&
            browserSupport.googlePay,
        applePay:
            isPaymentMethodEnabled(currencyPayments, 'applepay') &&
            browserSupport.applePay,
    }),
);

export const selectPaymentModes = createSelector(
    selectPayment,
    selectDonationPaymentMethod,
    selectCheckout,
    (payment, paymentMethod, checkout) => {
        if (
            paymentMethodType(paymentMethod) === 'voucher' &&
            !checkout.givingId
        ) {
            return {
                allowSinglePayment: true,
                allowInstallments: false,
                allowRecurring: voucherSupportsPaymentMode(
                    paymentMethod,
                    PAYMENT_MODES.MONTHLY,
                ),
                allowUnlimitedRecurring: false,
            };
        }

        if (
            payment.gateway === PAYMENT_GATEWAYS.STRIPE &&
            (paymentMethod === PAYMENT_METHODS.CASHAPP ||
                paymentMethod === PAYMENT_METHODS.IDEAL)
        ) {
            return {
                allowSinglePayment: payment.allowSinglePayment,
                allowRecurring: false,
            };
        }

        if (
            payment.gateway === PAYMENT_GATEWAYS.MESHULAM &&
            paymentMethod !== PAYMENT_METHODS.CARD
        ) {
            return {
                ...pick(payment, ['allowSinglePayment', 'allowInstallments']),
                allowRecurring: false,
            };
        }

        return pick(payment, [
            'allowSinglePayment',
            'allowInstallments',
            'allowRecurring',
            'allowUnlimitedRecurring',
        ]);
    },
);

export const selectAllowedPaymentModesValues = createSelector(
    selectPaymentModes,
    paymentModes =>
        [
            paymentModes.allowSinglePayment && PAYMENT_MODES.REGULAR,
            paymentModes.allowInstallments && PAYMENT_MODES.INSTALLMENTS,
            paymentModes.allowUnlimitedRecurring &&
                PAYMENT_MODES.UNLIMITED_RECURRING,
            paymentModes.allowRecurring && PAYMENT_MODES.RECURRING,
        ].filter(Boolean),
);

export const selectDefaultPaymentMode = createSelector(
    selectAllowedPaymentModesValues,
    selectAutoFilledPaymentMode,
    (paymentModes, autoFilledPaymentMode) => {
        if (paymentModes.includes(autoFilledPaymentMode)) {
            return autoFilledPaymentMode;
        }
        return paymentModes?.[0] || PAYMENT_MODES.REGULAR;
    },
);

export const selectEnabledPaymentMethods = createSelector(
    selectCurrencyPayments,
    selectGivingId,
    selectGivingPaymentOptions,
    selectBitEnabled,
    selectPaypalEnabled,
    selectAppleAndGooglePayEnabled,
    selectVouchers,
    (
        currencyPayments,
        givingId,
        givingPaymentOptions,
        bitEnabled,
        paypalEnabled,
        appleAndGooglePayEnabled,
        vouchers,
    ) => {
        let paymentMethods = [];
        if (bitEnabled) {
            paymentMethods.push('bit');
        }
        if (paypalEnabled) {
            paymentMethods.push('paypal');
        }
        if (appleAndGooglePayEnabled.applePay) {
            paymentMethods.push('applepay');
        }
        if (appleAndGooglePayEnabled.googlePay) {
            paymentMethods.push('googlepay');
        }
        if (
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                PAYMENT_METHODS.CASHAPP,
            ) &&
            givingPaymentOptions.type !== PAYMENT_MODES.RECURRING
        ) {
            paymentMethods.push(PAYMENT_METHODS.CASHAPP);
        }
        if (
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                PAYMENT_METHODS.IDEAL,
            ) &&
            givingPaymentOptions.type !== PAYMENT_MODES.RECURRING
        ) {
            paymentMethods.push(PAYMENT_METHODS.IDEAL);
        }
        if (
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                'chariot',
            )
        ) {
            paymentMethods.push('chariot');
        }
        if (
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                'pledger',
            )
        ) {
            paymentMethods.push('pledger');
        }
        if (
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                'matbia',
            )
        ) {
            paymentMethods.push('matbia');
        }

        if (
            vouchers?.includes(PAYMENT_METHODS.AAC) &&
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                PAYMENT_METHODS.AAC,
            )
        ) {
            paymentMethods.push(PAYMENT_METHODS.AAC);
        }

        if (
            vouchers?.includes(PAYMENT_METHODS.DONORSFUND) &&
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                PAYMENT_METHODS.DONORSFUND,
            )
        ) {
            paymentMethods.push(PAYMENT_METHODS.DONORSFUND);
        }

        if (
            vouchers?.includes(PAYMENT_METHODS.OJCFUND) &&
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                PAYMENT_METHODS.OJCFUND,
            )
        ) {
            paymentMethods.push(PAYMENT_METHODS.OJCFUND);
        }

        if (
            vouchers?.includes(PAYMENT_METHODS.ASSERBISHVIL) &&
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                PAYMENT_METHODS.ASSERBISHVIL,
            )
        ) {
            paymentMethods.push(PAYMENT_METHODS.ASSERBISHVIL);
        }

        if (
            vouchers?.includes(PAYMENT_METHODS.BROOM) &&
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                PAYMENT_METHODS.BROOM,
            )
        ) {
            paymentMethods.push(PAYMENT_METHODS.BROOM);
        }

        if (
            paymentMethods.length &&
            isPaymentMethodAllowed(
                currencyPayments,
                givingId,
                givingPaymentOptions,
                'card',
            )
        ) {
            paymentMethods.unshift('card');
        }
        return paymentMethods;
    },
);

export const selectStripeAccountIds = createSelector(
    selectPayments,
    selectCurrencies,
    (campaignPayments, campaignCurrencies) => {
        const stripeAccountIds = {};
        campaignCurrencies.forEach(currency => {
            const gatewayConfigList = get(
                campaignPayments,
                `${currency.value}`,
                [],
            );
            const stripeConfig =
                gatewayConfigList.find(({ gateway }) => gateway === 'stripe') ||
                {};
            stripeAccountIds[currency.value] = get(
                stripeConfig,
                `accountId`,
                '',
            );
        });
        return stripeAccountIds;
    },
);
export const selectStripeAccountId = createSelector(
    selectPayment,
    payment => payment.accountId,
);
export const selectStripeKey = createSelector(selectIsTestMode, isTestMode =>
    isTestMode ? config.stripeTestPublicKey : config.stripePublicKey,
);
export const selectGiftAid = createSelector(selectPayments, payments => {
    const enableGiftAid = get(payments, 'GBP.0.enableGiftAid', true);
    return { enableGiftAid };
});
export const selectTaxGift = createSelector(selectPayments, payments => {
    const enableTaxGift = get(payments, 'NZD.0.enableTaxGift', false);
    const taxGiftEntityId = get(payments, 'NZD.0.taxGiftEntityId', '');

    return { enableTaxGift, taxGiftEntityId };
});
export const selectTotalAmount = createSelector(
    selectPayment,
    selectDonationAmount,
    (payment, amount) => {
        const fee = payment.commission ? payment.commission / 100 : 0;
        return amount + amount * fee;
    },
);
export const selectFee = createSelector(selectPayment, payment => {
    if (isNaN(payment.commission)) {
        return 0;
    }
    return Number(payment.commission);
});
export const selectEnableTipping = createSelector(
    selectPayment,
    payment => payment.enableTipping || false,
);

export const selectEnableDynamicTipping = createSelector(
    selectPayment,
    payment => payment.enableDynamicTipping || false,
);

export const selectDynamicTipConfig = createSelector(
    selectDonationAmount,
    amount => getDynamicTipConfig(amount),
);

export const selectDefaultTipPercent = createSelector(
    selectPayment,
    selectEnableDynamicTipping,
    selectDynamicTipConfig,
    (payment, enableDynamicTipping, dynamicTipConfig) =>
        (enableDynamicTipping
            ? dynamicTipConfig.defaultTipPercent
            : payment.defaultTipPercent) || 0,
);
export const selectTipPercents = createSelector(
    selectPayment,
    selectEnableDynamicTipping,
    selectDynamicTipConfig,
    (payment, enableDynamicTipping, dynamicTipConfig) =>
        (enableDynamicTipping
            ? dynamicTipConfig.tippingPercents
            : payment.tippingPercents) || [],
);
export const selectFiscalSponsorConfig = createSelector(
    selectPayment,
    payment => ({
        enableFiscalSponsor: payment.enableFiscalSponsor || false,
        fiscalSponsor: payment.fiscalSponsor || null,
    }),
);
export const selectPaymentProcess = createSelector(
    selectCheckout,
    checkout => ({
        status: checkout.paymentProcessStatus,
        iteration: checkout.paymentProcessIteration,
        count: checkout.paymentProcessCount,
    }),
);
export const selectError = createSelector(
    selectCheckout,
    checkout => checkout.error,
);
export const selectAlert = createSelector(
    selectCheckout,
    checkout => checkout.alert,
);
export const selectCustomFields = createSelector(
    selectCheckoutCustomFields,
    selectDonationCurrency,
    (fields, currency) => get(fields, `${currency}.customFields`, {}),
);
export const selectDefaultFields = createSelector(
    selectCheckoutCustomFields,
    selectDonationCurrency,
    (fields, currency) =>
        get(
            fields,
            `${currency}.defaultFields`,
            get(fields, `USD.defaultFields`, {}),
        ),
);
export const selectMinDonationAmount = createSelector(
    selectDonationCurrency,
    currency => minDonationAmount(currency),
);

export default checkout.reducer;
