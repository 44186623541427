import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Select from '../Select/Select';

const useStyles = makeStyles({
    root: {
        display: 'block',
        borderColor: '#ccc',
        borderRadius: 5,
        boxSizing: 'border-box',
        '& select': {
            height: 54,
            padding: '0 35px 0 20px',
            boxSizing: 'border-box',
            fontFamily: 'Heebo, sans-serif',
            fontSize: 16,
            color: '#707070',

            '@media (max-width: 600px)': {
                height: 44,
            },
        },
        '&::before': {
            display: 'none',
        },
        '&::after': {
            width: 7,
            height: 7,
            right: 13,
            top: 'calc(50% - 1px)',
        },
    },
});

function FormSelect(props) {
    const {
        className,
        placeholder = '',
        options: defaultOptions = [],
        defaultSelectable = false,
        ...rest
    } = props;
    const classes = useStyles();
    const options = useMemo(() => {
        let options = defaultOptions;

        if (placeholder) {
            options.unshift({
                name: placeholder,
                disabled: !defaultSelectable,
                selected: false,
                hidden: !defaultSelectable,
                value: '',
            });
        }

        return options;
    }, [placeholder, defaultOptions]);

    return (
        <Select
            className={[classes.root, className]
                .filter(Boolean)
                .join(' ')
                .trim()}
            options={options}
            {...rest}
        />
    );
}

FormSelect.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.string,
    defaultSelectable: PropTypes.bool,
};

export default FormSelect;
