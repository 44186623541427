const CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES = Object.freeze({
    ASK_EN: 'AskEN',
});

const CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES_INFO = Object.freeze({
    [CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES.ASK_EN]:
        "{{NAME}}, Last year, you were a generous supporter of {{ORGANIZATION}}'s campaign. You donated {{AMOUNT}} and made a tremendous impact through your generosity. We've just launched a brand new campaign, and I'm turning to you again this year. Can I count on your support again? {{NEWER_CAMPAIGN_LINK}}",
});

const DONOR_WHATSAPP_TEMPLATES = Object.freeze({
    BEFORE_NEXT_CAMPAIGN_EN: 'BeforeNextCampaignEN',
    THANKYOU_EN: 'ThankYouEN',
});

const DONOR_WHATSAPP_TEMPLATES_INFO = Object.freeze({
    [DONOR_WHATSAPP_TEMPLATES.BEFORE_NEXT_CAMPAIGN_EN]:
        '{{NAME}}, you are part of the {{ORGANIZATION}} family. Your past generosity does not go unnoticed. In a short while, we are going to embark on a fundraising campaign, our most important one of the year. When we launch, can we count on your support again? Thank you. Truly and sincerely. Your commitment propels us forward!',
    [DONOR_WHATSAPP_TEMPLATES.THANKYOU_EN]:
        '{{NAME}}, thank you again for donating to our crowdfunding campaign. I am still so overwhelmed by your generosity and commitment to our cause. \n\nAs a donor to {{ORGANIZATION}}, you are the engine that makes our organization run. You own a share of every milestone. Your gift has had a tremendous impact. \n\nThank you for being a part of the {{ORGANIZATION}} family.',
});

const P2P_WHATSAPP_TEMPLATES = Object.freeze({
    GOAL_PROGRESS_25_EN: 'GoalProgress25EN',
    GOAL_PROGRESS_50_EN: 'GoalProgress50EN',
    GOAL_PROGRESS_75_EN: 'GoalProgress75EN',
    GOAL_PROGRESS_100_EN: 'GoalProgress100EN',
    PERSONAL_LINK_EN: 'PersonalFundraisingLinkEN',
    PERSONAL_LINK_HE: 'PersonalFundraisingLinkHE',
});

const P2P_WHATSAPP_TEMPLATES_INFO = Object.freeze({
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_25_EN]:
        "A standing ovation for {{NAME}}! 👏🎉 You've raised 25% of your personal goal through {{DONOR_COUNT}} donors. Your hard work is an inspiration to everyone working on this campaign. Keep shining and leading the way!",
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_50_EN]:
        "Sending you a round of applause for hitting 50% of your personal fundraising goal! 🌊💪 Go {{NAME}}, Go! Keep the pedal to the medal and let's finish strong!",
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_75_EN]:
        "You are almost home! 💙👏 You just hit 75% of your fundraising goal. We are so incredibly lucky to have you on our team. Don't stop now though. You've got momentum. Here we go!",
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_100_EN]:
        "Wow 🙌 wow 🙌 wow 🙌. You did it. 100% of your fundraising goal! There is still time left so would you consider setting a Bonus Goal for yourself? You have shown what you can do when you put your mind to it. Let's keep it going until the final whistle blows!",
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_EN]:
        'Dear {{NAME}}, \nThis is the personal link for your fundraiser {{PERSONAL_LINK}} \nPlease help us reach the goal by *sharing the campaign with friends and family*.',
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_HE]:
        'שלום {{NAME}}, \nתודה על הרשמתך כשגריר/ה בקמפיין של {{ORGANIZATION}}! \nהנה הקישור לדף עם היעד האישי שלך: {{PERSONAL_LINK}}. \nלאחר תחילת הקמפיין, עזור/עזרי לנו להגיע למטרה על ידי שיתוף הקישור עם חברים, מכרים ובני משפחה! \nאנחנו כאן כדי לעזור, אל תהסס/י לפנות אלינו לכל שאלה ולתמיכה. \nתודה שוב על ההתגייסות! \n{{ORGANIZATION}}',
});

const WHATSAPP_TEMPLATES_INFO = Object.freeze({
    ...CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES_INFO,
    ...DONOR_WHATSAPP_TEMPLATES_INFO,
    ...P2P_WHATSAPP_TEMPLATES_INFO,
});

const WHATSAPP_TEMPLATES_TWILIO_SID_MAP = Object.freeze({
    [CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES.ASK_EN]:
        'HX29fc20aa3aa61390e7871088dce5e797',
    [DONOR_WHATSAPP_TEMPLATES.BEFORE_NEXT_CAMPAIGN_EN]:
        'HX3db9b19b84fa8f1840b7a234147b058f',
    [DONOR_WHATSAPP_TEMPLATES.THANKYOU_EN]:
        'HXc97d3a35dcaa5a29310d1bc36fea95be',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_25_EN]:
        'HX07d2f08af939ea0a88f6633bc461b989',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_50_EN]:
        'HX853161a4af1c74eb1845ba0df98d09dd',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_75_EN]:
        'HX1e9614e9fac6579c6da541ed5d3efb43',
    [P2P_WHATSAPP_TEMPLATES.GOAL_PROGRESS_100_EN]:
        'HX9fd3f6878f72e808ff19c3adc66c05dd',
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_EN]:
        'HX06d24ddca946a6651b8dfc5c823e978a',
    [P2P_WHATSAPP_TEMPLATES.PERSONAL_LINK_HE]:
        'HX2dafb615a56e5005c884e53ac4a9784b',
});

module.exports = {
    CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES,
    CAMPAIGN_COMPARE_WHATSAPP_TEMPLATES_INFO,
    DONOR_WHATSAPP_TEMPLATES,
    DONOR_WHATSAPP_TEMPLATES_INFO,
    P2P_WHATSAPP_TEMPLATES,
    P2P_WHATSAPP_TEMPLATES_INFO,
    WHATSAPP_TEMPLATES_INFO,
    WHATSAPP_TEMPLATES_TWILIO_SID_MAP,
};
