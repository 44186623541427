import React, { useState, useEffect, useCallback } from 'react';
import { setIsOpen, resetCheckoutPopup } from './checkoutPopupSlice';
import CheckoutPopup from '../../components/CheckoutPopup/CheckoutPopup';
import PopupMessage from '../../components/PopupMessage/PopupMessage';
import DonationForm from './DonationForm';
import Loader from 'client/components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDonationCurrency,
    resetCheckout,
    paymentFailed,
    updateApplePaySupport,
    updateGooglePaySupport,
    resetAutoFilledValues,
    setPaymentAlert,
    resetGiving,
} from './checkoutSlice';
import {
    selectCampaignSlug,
    selectCheckoutHeroSlide,
    selectLandingData,
    selectPageSpecificCampaignData,
    selectPageName,
    selectSelectedCampaignId,
    selectSelectedCampaignTitle,
    selectSelectedCampaignAccountId,
    selectSelectedLayerItem,
} from '../campaign/campaignSlice';
import HeroSlider from '../../components/HeroSlider/HeroSlider';
import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApplePay, useGooglePay } from '../../hooks/paymentMethod';
import { get, pick } from 'lodash';
import filterQueryParams from 'client/helpers/filterQueryParams';
import { DONATION_CONVERSION_PARAMS } from 'common/constants';
import TaxGiftRedirect from './TaxGiftRedirect';
import { GTM_EVENTS, triggerGtmEvent } from 'client/helpers/gtm';
import PostDonationRedirect from './PostDonationRedirect';
import { setDonorPfl } from '../../components/SharePanel/sharePanelSlice';
import { createDonorPfl, donorPflPrepareUrl } from 'common/helpers/donorPfl';
import getPaymentModeName from 'common/helpers/getPaymentModeName';
import { resetLastDonation, setLastDonation } from './lastDonationSlice';
import { sendAnalyticsEvent } from '../../../../services/analytics';

function DonationPopup() {
    const dispatch = useDispatch();
    const currency = useSelector(selectDonationCurrency);
    const [isMounted, setIsMounted] = useState(false);
    const [isDonated, setIsDonated] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [isTaxGift, setIsTaxGift] = useState(false);
    const campaignSlug = useSelector(selectCampaignSlug);
    const history = useHistory();
    const { formatMessage } = useIntl();
    const checkoutHeroSlide = useSelector(selectCheckoutHeroSlide);
    const {
        enableFb,
        enableGa,
        enableGAd,
        enableGAdConversionTag,
        fbTrackingId,
        gaTrackingId,
        gAdTrackingId,
        gAdLabel,
        gAdConversionTagId,
        gAdConversionTagLabel,
    } = useSelector(selectLandingData);
    const { name: campaignOrgName } = useSelector(
        selectPageSpecificCampaignData,
    );
    const campaignPageName = useSelector(selectPageName);
    const campaignPageId = useSelector(selectSelectedCampaignId);
    const campaignTitle = useSelector(selectSelectedCampaignTitle);
    const queryParams = qs.parse(document.location.search);
    for (let param in queryParams) {
        if (
            DONATION_CONVERSION_PARAMS.includes(param) &&
            Array.isArray(queryParams[param])
        ) {
            queryParams[param] = queryParams[param][0];
        }
    }
    const campaignPageUrl = `${window.location.origin}${
        window.location.pathname.match(/^\/([\w-]{2,})/)[0]
    }`;

    const googlePayStatus = useGooglePay();
    const applePayStatus = useApplePay();
    const campaignAccountId = useSelector(selectSelectedCampaignAccountId);
    const pageLayerItem = useSelector(selectSelectedLayerItem);

    const getLastDonationData = (data, donationCount = 1) => ({
        ...pick(data, [
            'currency',
            'email',
            'firstName',
            'lastName',
            'isAnonymous',
            'phone',
            'phonePrefix',
        ]),
        amount: Math.round(get(data, 'amount') * donationCount),
        recurringMonths: get(data, 'recurringMonths', get(data, 'period', 1)),
        language: get(
            data,
            'language',
            get(data, ['campaign', 'locales', data?.page, 'language'], 'en'),
        ),
        organization: get(
            data,
            'organization',
            get(data, ['campaign', 'locales', data?.page, 'name']),
        ),
        paymentMode: getPaymentModeName(data?.paymentMode),
        campaignId: campaignPageId,
        campaignName: campaignTitle,
    });

    const sendDonationFormEvent = useCallback(
        event => {
            const analyticsEventData = {
                event,
                org_account_id: campaignAccountId,
                campaign_id: campaignPageId,
                campaign_sub_page: campaignPageName,
                page_language:
                    pageLayerItem?.language || document.documentElement.lang,
                is_layer_item_page: !!pageLayerItem,
            };
            sendAnalyticsEvent(analyticsEventData);
        },
        [campaignAccountId, campaignPageId, campaignPageName, pageLayerItem],
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        dispatch(updateGooglePaySupport(googlePayStatus.isAllowed));
    }, [googlePayStatus.isAllowed]);

    useEffect(() => {
        dispatch(updateApplePaySupport(applePayStatus.isAllowed));
    }, [applePayStatus.isAllowed]);

    useEffect(() => {
        if (!(isRedirect || isDonated) && isMounted) {
            sendDonationFormEvent('campaign:donation_form_view');
        }
    }, [isMounted, isRedirect, isDonated, sendDonationFormEvent]);

    useEffect(() => {
        const query = qs.parse(document.location.search);

        if (query.payment_intent && query.redirect_status) {
            const status =
                query.redirect_status === 'succeeded' ? 'success' : 'failure';

            query.status = status;
            delete query.payment_intent;
            delete query.payment_intent_client_secret;
            delete query.redirect_status;

            window.history.replaceState(
                null,
                '',
                `${window.location.pathname}?${qs.stringify(query)}`,
            );
        }

        if (query.status === 'success') {
            setIsDonated(true);
            if (query.donorPflSlug) {
                dispatch(
                    setDonorPfl(
                        donorPflPrepareUrl(
                            campaignPageUrl,
                            query.donorPflSlug,
                            query.donorName,
                        ),
                    ),
                );
            }
        } else if (query.status === 'failure') {
            // rendering the donation form resets error state,
            // so the failure should be dispatched after render
            setTimeout(
                () =>
                    dispatch(
                        paymentFailed(
                            formatMessage({
                                id: 'DonationForm.error.paymentFailed',
                            }),
                        ),
                    ),
                2000,
            );
        }
        if (Object.keys(query).includes('taxGift')) {
            setIsTaxGift(true);
        }
    }, [setIsDonated, setIsTaxGift, dispatch, formatMessage]);

    const handleSuccessDonate = useCallback(result => {
        if (result.redirect) {
            setIsRedirect(true);
        } else {
            setIsDonated(true);
            const donations = result.data?.donations || null;
            if (donations?.[0] && queryParams.status != 'success') {
                dispatch(
                    setLastDonation(
                        getLastDonationData(donations[0], donations?.length),
                    ),
                );
                triggerGtmEvent(null, { ecommerce: null });
                triggerGtmEvent(GTM_EVENTS.PURCHASE, {
                    fbIdArray: enableFb ? fbTrackingId : 'false',
                    gaIdArray: enableGa ? gaTrackingId : 'false',
                    gAdId: enableGAd ? gAdTrackingId : 'false',
                    gAdLabel: enableGAd ? gAdLabel : 'false',
                    gAdConversionTagId: enableGAdConversionTag
                        ? gAdConversionTagId
                        : 'false',
                    gAdConversionTagLabel: enableGAdConversionTag
                        ? gAdConversionTagLabel
                        : 'false',
                    donationId: donations[0].id,
                    amount: parseInt(donations[0].amount) * donations.length,
                    currency: donations[0].currency,
                    quantity: parseInt(donations[0].recurringMonths),
                    amountTotal:
                        parseInt(donations[0].amount) *
                        donations.length *
                        parseInt(donations[0].recurringMonths),
                    campaignId: campaignPageId,
                    campaignTitle: campaignPageName,
                    orgName: campaignOrgName,
                    fbContentIds: [donations[0].id],
                    ecommerce: {
                        currencyCode: donations[0].currency,
                        purchase: {
                            actionField: {
                                id: donations[0].id,
                                affiliation: `CauseMatch Campaign - ${campaignOrgName}`,
                            },
                            products: [
                                {
                                    name: campaignPageName,
                                    id: campaignPageId,
                                    price:
                                        parseInt(donations[0].amount) *
                                        donations.length,
                                    brand: campaignOrgName,
                                    quantity: parseInt(
                                        donations[0].recurringMonths,
                                    ),
                                },
                            ],
                        },
                    },
                });
                if (donations[0].taxGift) {
                    setIsTaxGift(true);
                }
                if (result.data?.donorPflId) {
                    dispatch(
                        setDonorPfl(
                            createDonorPfl(
                                result.data.donorPflId,
                                campaignPageUrl,
                                {
                                    firstName: !donations[0].isAnonymous
                                        ? donations[0].firstName
                                        : '',
                                    lastName: !donations[0].isAnonymous
                                        ? donations[0].lastName
                                        : '',
                                },
                            ),
                        ),
                    );
                }
            }
        }
    });
    const renderContent = useCallback(() => {
        if (isDonated) {
            //GTM track
            if (queryParams.status == 'success') {
                dispatch(setLastDonation(getLastDonationData(queryParams)));
                triggerGtmEvent(null, { ecommerce: null });
                triggerGtmEvent(GTM_EVENTS.PURCHASE, {
                    fbIdArray: enableFb ? fbTrackingId : 'false',
                    gaIdArray: enableGa ? gaTrackingId : 'false',
                    gAdId: enableGAd ? gAdTrackingId : 'false',
                    gAdLabel: enableGAd ? gAdLabel : '',
                    gAdConversionTagId: enableGAdConversionTag
                        ? gAdConversionTagId
                        : 'false',
                    gAdConversionTagLabel: enableGAdConversionTag
                        ? gAdConversionTagLabel
                        : 'false',
                    donationId: queryParams.donation,
                    amount: parseInt(queryParams.amount),
                    currency: queryParams.currency,
                    quantity: parseInt(queryParams.period),
                    amountTotal:
                        parseInt(queryParams.amount) *
                        parseInt(queryParams.period),
                    campaignId: campaignPageId,
                    campaignTitle: campaignPageName,
                    orgName: campaignOrgName,
                    fbContentIds: [queryParams.donation],
                    //enhanced ecommerce object
                    ecommerce: {
                        currencyCode: queryParams.currency,
                        purchase: {
                            actionField: {
                                id: queryParams.donation,
                                affiliation: `CauseMatch Campaign - ${campaignOrgName}`,
                            },
                            products: [
                                {
                                    name: campaignPageName,
                                    id: campaignPageId,
                                    price: parseInt(queryParams.amount),
                                    brand: campaignOrgName,
                                    quantity: parseInt(queryParams.period),
                                },
                            ],
                        },
                    },
                });
            }
            return (
                <PopupMessage>
                    <FormattedMessage
                        id="CheckoutPopup.successDonation"
                        defaultMessage="Thank you for your donation!"
                    />
                    {isTaxGift && <TaxGiftRedirect />}
                    <PostDonationRedirect />
                </PopupMessage>
            );
        } else if (isRedirect) {
            return (
                <PopupMessage>
                    <FormattedMessage
                        id="renderContent.youAreBeingRedirected"
                        defaultMessage="You are being redirected to the payment gateway..."
                    />
                </PopupMessage>
            );
        } else if (isMounted) {
            return <DonationForm onDonateSuccess={handleSuccessDonate} />;
        }

        return <Loader />;
    }, [isDonated, isMounted, isTaxGift, currency]);

    const handleClose = () => {
        dispatch(setIsOpen(false));
        dispatch(setPaymentAlert(null));
        dispatch(resetGiving());
        dispatch(resetCheckoutPopup());
        dispatch(resetAutoFilledValues());
        dispatch(setDonorPfl(null));
        const { filteredQueryString: queryString } = filterQueryParams(
            document.location.search,
            [
                'amount',
                'currency',
                'email',
                'firstName',
                'isAnonymous',
                'language',
                'lastName',
                'paymentMode',
                'phone',
                'phonePrefix',
                'recurringMonths',
                'response',
                'cField1',
            ],
        );

        const shouldUseCloseUrl = Boolean(qs.parse(queryString)['response']);

        const closeURL = `${
            location.pathname.split(/\/donate[\s/]*$/)[0]
        }/${queryString}`;
        const virtualLocation = `/${
            closeURL && shouldUseCloseUrl ? closeURL : campaignSlug
        }`;
        triggerGtmEvent(GTM_EVENTS.CLOSE_CHECKOUT, {
            eventCategory: 'virtual-pageview',
            virtualLocation: virtualLocation,
        });
        history.push(virtualLocation);
        if (isDonated) {
            dispatch(resetCheckout());
            dispatch(resetAutoFilledValues());
            dispatch(resetLastDonation());
        } else {
            sendDonationFormEvent('campaign:donation_form_close');
        }

        window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (googlePayStatus.isChecked && applePayStatus.isChecked) {
            setIsMounted(true);
        }
    }, [googlePayStatus.isChecked, applePayStatus.isChecked]);

    return (
        <>
            {checkoutHeroSlide && (
                <HeroSlider
                    slides={
                        Array.isArray(checkoutHeroSlide)
                            ? checkoutHeroSlide
                            : checkoutHeroSlide?.images
                    }
                    video={checkoutHeroSlide?.video}
                    mobileSlides={checkoutHeroSlide?.mobileImages}
                    isMobileImagesEnabled={
                        checkoutHeroSlide?.mobileImages?.length > 0
                    }
                />
            )}
            <CheckoutPopup
                isOpen={true}
                onClose={handleClose}
                isDonated={isDonated}
                isRedirected={isRedirect}
            >
                {renderContent()}
            </CheckoutPopup>
        </>
    );
}

export default React.memo(DonationPopup, (prevProps, nextProps) => {
    return prevProps.isOpen === nextProps.isOpen;
});
